<template>
  <div class="img-wrapper i-border">
    <div class="img-container"
         :class="'img-container-' + align">
      <b-img-lazy
          :src="imagePath"
          :alt="alt"
          class="full-img"
          :class="'full-img-' + align"
      />
<!--      <picture>-->
<!--        <source media="(max-width: 475px)"-->
<!--                :srcset="imagePathSmall">-->
<!--        <source media="(min-width: 476px)"-->
<!--                :srcset="imagePath">-->
<!--        <b-img-lazy-->
<!--            :src="imagePath"-->
<!--            :alt="alt"-->
<!--            class="full-img"-->
<!--            :class="'full-img-' + align"-->
<!--        />-->
<!--      </picture>-->
      <slot name="card"></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    imagePath: String,
    // imagePathSmall: String,
    alt: String,
    small: {
      type: Boolean,
      default: false
    },
    align: {
      type: String, //bottom
      default: 'center'
    }
  }
}
</script>

<style lang="sass" scoped>
.full-img
  height: auto !important
  width: 100% !important
  display: block
  transform: translateY(-100%)
  object-fit: cover
  object-position: bottom
  &.full-img-center
    transform: translateY(-50%)
  &.full-img-bottom
    transform: translateY(-100%)

.img-container
  max-height: inherit
  &.img-container-center
    transform: translateY(50%)
  &.img-container-bottom
    transform: translateY(100%)


.img-wrapper
  height: auto
  max-height: 400px
  overflow: hidden
  @media screen and (min-width: $sm)
    max-height: 358px
  @media screen and (min-width: $md)
    max-height: 520px
  @media screen and (min-width: $lg)
    max-height: 812px
  @media screen and (min-width: $xl)
    max-height: 850px
    width: 1920px
    margin-left: auto
    margin-right: auto
</style>

<template>
  <page-skeleton theme="light">
    <section-half-image
        arrow
        scroll-to="cosmetic-defects-arrow"
        hero
        photo-right-align
        hero-large-position="bottom"
        img-background="reverse-gradient"
        :alt="$t('challenges.cosmetic.images.alt1')"
        image-path="https://ik.imagekit.io/isotronic/challenges/cosmetic/vial-cosmetic-defect_kyDUxUK7HeC.png?updatedAt=1635403079664"
    >
      <template #header>
        {{ $t('challenges.cosmetic.hero.header') }}
      </template>
      <template #content>
        <div class="mb-3 i-font-text i-secondary">
          {{ $t('challenges.cosmetic.hero.text') }}
        </div>
      </template>
    </section-half-image>
    <section-half-image
        id="cosmetic-defects-arrow"
        image-path="https://ik.imagekit.io/isotronic/challenges/cosmetic/airline_vial_bottom_DTFmJayXD3.jpg?updatedAt=1635403181296"
        :alt="$t('challenges.cosmetic.section1.alt')"
    >
      <template #header>
        {{ $t('challenges.cosmetic.section1.header') }}
      </template>
      <template #content>
        <div>
          {{ $t('challenges.cosmetic.section1.subheader') }}
        </div>
        <div class="mt-3 mb-1">
          {{ $t('challenges.cosmetic.section1.subheader2') }}
        </div>
        <ul class="mt-3 mb-0">
          <li>
            {{ $t('challenges.cosmetic.section1.feature1') }}
          </li>
          <li>
            {{ $t('challenges.cosmetic.section1.feature2') }}
          </li>
          <li>
            {{ $t('challenges.cosmetic.section1.feature3') }}
          </li>
          <li>
            {{ $t('challenges.cosmetic.section1.feature4') }}
          </li>
          <li>
            {{ $t('challenges.cosmetic.section1.feature5') }}
          </li>
          <li>
            {{ $t('challenges.cosmetic.section1.feature6') }}
          </li>
          <li>
            {{ $t('challenges.cosmetic.section1.feature7') }}
          </li>
        </ul>
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/challenges/cosmetic/cosmetic_classification_eXM8bpT6Ug.png?updatedAt=1635403225929"
        background="light"
        photo-right-align
        img-background="solid"
        small-photo
        :alt="$t('challenges.cosmetic.section2.alt')"
    >
      <template #header>
        {{ $t('challenges.cosmetic.section2.header') }}
      </template>
      <template #content>
        <p>
          {{ $t('challenges.cosmetic.section2.subheader') }}
        </p>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/challenges/cosmetic/engineer_with_vialchecker_wbZtHIH-DL.jpg?updatedAt=1635403268783"
        :alt="$t('challenges.cosmetic.images.alt2')" />
    <features />
    <section-half-image
        :alt="$t('challenges.cosmetic.section4.alt')"
        image-path="https://ik.imagekit.io/isotronic/challenges/cosmetic/vials_VE47NqX3Lo.jpg?updatedAt=1635403306566"
    >
      <template #header>
        {{ $t('challenges.cosmetic.section4.header') }}
      </template>
      <template #content>
        <div class="mb-3 i-font-text i-secondary">
          {{ $t('challenges.cosmetic.section4.text') }}
        </div>
      </template>
    </section-half-image>
  </page-skeleton>

</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";
import Features from "@/components/industryChallenges/cosmeticDefects/CosmeticFeatures";

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage,
    Features
  },
  metaInfo() {
    return {
      title: this.$t('challenges.cosmetic.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('challenges.cosmetic.meta.description')}
      ]
    }
  },
}
</script>

<template>
  <b-card class="feature-card mb-2 mb-sm-4 p-0">
    <b-row class="w-100 mx-0 px-0">
      <b-col cols="auto" class="px-0 pt-0 my-auto feature-icon">
        <b-img-lazy fluid :src="src"
                    class="feature-icon ml-sm-0"
                    :alt="alt"/>
      </b-col>
      <b-col class="my-auto text-part text-left">
        <div class="i-card-header i-primary i-font-text-bolder mt-1 product-header">
          <slot name="header"></slot>
        </div>
        <div class="i-secondary product-sub-header i-text-small i-font-text-light mt-1">
          <slot name="subheader"></slot>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

export default {
  props: {
    src: String,
    alt: String
  }
}
</script>

<style lang="sass">
.feature-card
  height: auto
  border-radius: 30px
  border: none
  box-shadow: 0px 0px 24px rgba(227, 39, 38, 0.08)
  height: calc(100% - 1.15em)

.feature-icon
  width: 70px
  @media screen and (min-width: $sm)
    width: 80px
  @media screen and (min-width: $xl)
    width: 150px
</style>

<template>
  <b-row class="justify-content-center i-border i-outer-padding central-section i-vertical-no-photo">
    <b-col cols="12" sm="9" md="6"
           class="text-uppercase i-primary text-left text-sm-center">
      <h2>
        {{ $t('challenges.cosmetic.section3.header') }}
      </h2>
    </b-col>
    <div class="w-100" />
    <b-col cols="12" md="8" lg="7" class="pt-3 pb-4 pb-md-5 text-left text-sm-center">
      <div class="i-secondary i-text">
        {{ $t('challenges.cosmetic.section3.subheader') }}
      </div>
    </b-col>
    <div class="w-100"></div>
    <b-col v-for="(feature, index) in features"
           :key="'feature'+index"
           cols="12" md="6" lg="5">
      <feature-tile
          :src="feature.src"
          :alt="feature.alt"
      >
        <template #header>
          {{feature.title}}
        </template>
        <template #subheader>
          {{feature.text}}
        </template>
      </feature-tile>
    </b-col>
  </b-row>
</template>

<script>
import FeatureTile from "@/components/utility/FeatureTile"
export default {
  components: {
    FeatureTile
  },
  data() {
    return {
      features: [
        {
          title: this.$t('challenges.cosmetic.section3.features.feature1.title'),
          text: this.$t('challenges.cosmetic.section3.features.feature1.text'),
          alt: this.$t('challenges.cosmetic.section3.features.feature1.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/cosmetic/icons/high_accuracy_Gsom97eAD.svg?updatedAt=1633135058929',
        },
        {
          title: this.$t('challenges.cosmetic.section3.features.feature2.title'),
          text: this.$t('challenges.cosmetic.section3.features.feature2.text'),
          alt: this.$t('challenges.cosmetic.section3.features.feature2.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/cosmetic/icons/analysis_over_time_jCGgwtJmz6C.svg?updatedAt=1633135059269',
        },
        {
          title: this.$t('challenges.cosmetic.section3.features.feature3.title'),
          text: this.$t('challenges.cosmetic.section3.features.feature3.text'),
          alt: this.$t('challenges.cosmetic.section3.features.feature3.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/cosmetic/icons/real_time_FGmE7m_v6.svg?updatedAt=1633135058513',
        },
        {
          title: this.$t('challenges.cosmetic.section3.features.feature4.title'),
          text: this.$t('challenges.cosmetic.section3.features.feature4.text'),
          alt: this.$t('challenges.cosmetic.section3.features.feature4.alt'),
          src: 'https://ik.imagekit.io/isotronic/challenges/cosmetic/icons/high_speed_-9DAKIiUWWL.svg?updatedAt=1633135058605',
        },
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.header-grey
  text-transform: none!important

.central-section
  background: rgba(100, 101, 104, 0.05)
</style>
